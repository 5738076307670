exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-lovers-watches-js": () => import("./../../../src/pages/all-lovers-watches.js" /* webpackChunkName: "component---src-pages-all-lovers-watches-js" */),
  "component---src-pages-all-mens-watches-js": () => import("./../../../src/pages/all-mens-watches.js" /* webpackChunkName: "component---src-pages-all-mens-watches-js" */),
  "component---src-pages-all-womens-watches-js": () => import("./../../../src/pages/all-womens-watches.js" /* webpackChunkName: "component---src-pages-all-womens-watches-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mens-best-sellers-js": () => import("./../../../src/pages/mens-best-sellers.js" /* webpackChunkName: "component---src-pages-mens-best-sellers-js" */),
  "component---src-pages-mens-new-releases-js": () => import("./../../../src/pages/mens-new-releases.js" /* webpackChunkName: "component---src-pages-mens-new-releases-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shipping-js": () => import("./../../../src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-track-order-js": () => import("./../../../src/pages/track-order.js" /* webpackChunkName: "component---src-pages-track-order-js" */),
  "component---src-pages-womens-best-sellers-js": () => import("./../../../src/pages/womens-best-sellers.js" /* webpackChunkName: "component---src-pages-womens-best-sellers-js" */),
  "component---src-pages-womens-new-releases-js": () => import("./../../../src/pages/womens-new-releases.js" /* webpackChunkName: "component---src-pages-womens-new-releases-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-simpleproduct-js": () => import("./../../../src/templates/simpleproduct.js" /* webpackChunkName: "component---src-templates-simpleproduct-js" */)
}

