module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"starkingwatches.com – Starking Watch Official Store","short_name":"💯 Starking Watch","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e2a8352858cac9553e6963c4e1463dd6"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","url":"https://www.starkingwatches.com/","site_name":"Starking Watch Official Store"},"twitter":{"handle":"@onemorewatches","site":"https://www.starkingwatches.com","cardType":"summary_large_image"},"facebook":{"appId":"2636999079954760"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-shopify-poulo/gatsby-browser.js'),
      options: {"plugins":[],"activatePWA":true,"dirName":"E:\\learning\\gatsby\\starkingwatches\\starkingwatches","navigation":{"menu":[{"type":"Menu","displayName":"MENS","link":"","subMenu":[{"link":"/mens-best-sellers","displayName":"BEST SELLERS"},{"link":"/mens-new-releases","displayName":"NEW RELEASES"},{"link":"/all-mens-watches","displayName":"WATCHES"}]},{"type":"Menu","displayName":"WOMENS","link":"","subMenu":[{"link":"/womens-best-sellers","displayName":"BEST SELLERS"},{"link":"/womens-new-releases","displayName":"NEW RELEASES"},{"link":"/all-womens-watches","displayName":"WATCHES"}]},{"type":"Menu","displayName":"LOVERS","link":"","subMenu":[{"link":"/all-lovers-watches","displayName":"WATCHES"}]}]},"footerNavigation":{"menu":[{"type":"Link","displayName":"SHOP","link":"","subMenu":[{"displayName":"Mens","link":"/all-mens-watches"},{"displayName":"Womens","link":"/all-womens-watches"},{"displayName":"Lovers","link":"/all-lovers-watches"},{"displayName":"About Us","link":"/about"}]},{"type":"Link","displayName":"HELP","link":"","subMenu":[{"displayName":"Shipping","link":"/shipping"},{"displayName":"Returns","link":"/returns"},{"displayName":"FAQ & Contact","link":"/contact"},{"displayName":"Order Track","link":"/track-order"}]},{"type":"Support","link":null,"displayName":"","subMenu":[{"displayName":"Terms & Conditions","link":"/terms-of-service"},{"displayName":"Privacy Policy","link":"/privacy-policy"}]}]},"social":{"youtube":"","instagram":"starkingwatch","facebook":"","pinterest":"","twitter":"","snapchat":""},"paymentMethods":["visa","mastercard","amex","diners","discover","jcb"],"shopifyOptions":{"password":"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","storeUrl":"onemorewatch.myshopify.com","shopifyConnections":["collections"],"salesChannel":"starking"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.starkingwatches.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
